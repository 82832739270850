<template>
  <div class="vx-card p-6">
    <base-ag-grid-header>
      <template v-slot:header-buttons>
        <list-action-drop-down
          @export="exportData"
          @delete="confirmDeleteRecords">
        </list-action-drop-down>
      </template>

      <template v-slot:header-search-box>
        <vs-input
          v-model="searchQuery"
          :placeholder="`${$t('$General.Search')}...`"
          class="w-full md:w-auto order-3 md:order-normal mt-4 md:mt-0 "
          @input="updateSearchQuery"/>
      </template>
    </base-ag-grid-header>

    <ag-grid-vue
      domLayout='autoHeight'
      :style="{height: '100% !important'}"
      ref="agGridTable"
      :components="components"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 ag-grid-table"
      :columnDefs="columnDefs"
      :deltaColumnMode="true"
      :defaultColDef="defaultColDef"
      :column-types="columnTypes"
      :rowData="users"
      :immutableData="true"
      :getRowNodeId="getRowNodeId"
      :enableCellChangeFlash="true"
      :autoParamsRefresh="true"
      rowSelection="multiple"
      :animateRows="true"
      :floatingFilter="true"
      :pagination="true"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="true"
      :suppressRowClickSelection="true"
      :enableRtl="$vs.rtl"
      @selectionChanged="onSelectionChanged"
      @pagination-changed="onPaginationChanged">
    </ag-grid-vue>

    <base-ag-grid-footer>
      <template v-slot:items-per-page>
        <items-per-page
          :data="users"
          :gridApi="gridApi"
          :currentPage="currentPage"
          :paginationPageSize="paginationPageSize">
        </items-per-page>
      </template>

      <template v-slot:pagination>
        <vs-pagination
          v-model="currentPage"
          :total="totalPages"
          :max="7"/>
      </template>
    </base-ag-grid-footer>

    <vs-popup
      :title="exportModalTitle"
      :active.sync="activeModalToExport">
      <transition name="zoom-fade">
        <export-json-to-excel
          v-if="showExportComponent"
          :columns="getColumnsToExport"
          :data="rowsSelected"
          @close="activeModalToExport=false">
        </export-json-to-excel>
      </transition>
    </vs-popup>
  </div>
</template>

<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import { mapActions, mapState, mapGetters } from 'vuex';

// Custom components
import BaseAgGridHeader from '../_components/BaseAgGridHeader.vue';
import BaseAgGridFooter from '../_components/BaseAgGridFooter.vue';
import ItemsPerPage from '../_components/ItemsPerPage.vue';
import ListActionDropDown from '../_components/ListActionDropDown.vue';
import ExportJsonToExcel from '../_components/ExportJsonToExcel.vue';

// Cell Renderer
import MerchantListCellRendererLink from './MerchantListCellRendererLink.vue';
import MerchantListCellRendererStatus from './MerchantListCellRendererStatus.vue';
import CellRendererActions from '../_components/cell-renderer/SingleCellRendererActions.vue';

// Mixins
import commonAgGrid from '../_mixins/commonAgGrid';
import commonListCreateOrEdit from '../_mixins/commonListCreateOrEdit';

/**
 * Component to list and manage users
 *
 * @module views/modules/user/UserList
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} [entity='User'] - Name of the entity associated to list
 * @vue-data {Array.<Object>} columnDefs - Columns definition of ag grid to list
 * @vue-data {Object} components - Components to use to extend ag grid
 * @vue-computed {Array.<Object>} users - Users to list. Mapped from
 * @vue-event {Promise<Void>} fetchPrimaryData - fetch primary data to list in the component
 * @vue-event {Promise<Void>} afterFetchPrimaryData -
 * make secondary operation after fetch primary data
 * @vue-event {void} setPinnedEmail - pinned column email to left based on device resolution
 * @vue-event {void} afterMounted - called after mounted to call setPinnedEmail
 */
export default {
  name: 'MerchantList',
  components: {
    BaseAgGridHeader,
    BaseAgGridFooter,
    ItemsPerPage,
    ListActionDropDown,
    ExportJsonToExcel,
    // eslint-disable-next-line vue/no-unused-components
    MerchantListCellRendererLink,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererActions,
    // eslint-disable-next-line vue/no-unused-components
    MerchantListCellRendererStatus,
  },
  mixins: [commonAgGrid, commonListCreateOrEdit],
  data() {
    return {
      entity: 'Merchant',
      columnDefs: [
        {
          headerName: this.$tc('$General.Email'),
          field: 'email',
          filter: true,
          minWidth: 350,
          maxWidth: 500,
          cellRendererFramework: 'MerchantListCellRendererLink',
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true,
        },
        {
          headerName: this.$t('$General.FirstName'),
          field: 'firstName',
          filter: true,
          minWidth: 200,
          maxWidth: 300,
        },
        {
          headerName: this.$t('$General.LastName'),
          field: 'lastName',
          filter: true,
          minWidth: 200,
          maxWidth: 300,
        },
        {
          headerName: this.$t('$General.Status'),
          field: 'status',
          filter: true,
          minWidth: 150,
          maxWidth: 200,
          cellRendererFramework: 'MerchantListCellRendererStatus',
        },
      ],
      components: {
        MerchantListCellRendererLink,
        CellRendererActions,
        MerchantListCellRendererStatus,
      },
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.user.collection,
      authUser: (state) => state.auth.info.user,
    }),
    ...mapGetters({
      roleIsMerchant: 'auth/roleIsAdmin',
    }),
  },
  watch: {
    $mq() {
      this.setPinnedEmail();
    },
  },
  methods: {
    ...mapActions({
      fetchAllMerchants: 'user/fetchAllMerchants',
      fetchAllAudiences: 'audience/fetchAllAudiences',
      removeItem: 'user/removeUser',
      removeItems: 'user/removeUsers',
    }),
    async fetchPrimaryData() {
      await this.fetchAllMerchants();
    },
    async afterFetchPrimaryData() {
      if (this.roleIsMerchant) {
        await this.fetchAllAudiences();
      }

      this.autoSizeColumns();
    },
    setPinnedEmail() {
      if (this.$mq === 'laptop' || this.$mq === 'desktop') {
        this.gridColumnApi.setColumnPinned('email', 'left');
      } else {
        this.gridColumnApi.setColumnPinned('email', null);
      }
    },
    afterMounted() {
      this.setPinnedEmail();
    },
  },
};
</script>
