<template>
  <div class="flex items-center overflow-auto">
    <vs-avatar
      class="flex-shrink-0 mr-2"
      size="30px"
      :src="params.data.avatar"
      @click="clickAction" />

    <a
      href="#"
      class="text-inherit hover:text-primary"
      @click.stop.prevent="clickAction">
      {{ params.value }}
    </a>
  </div>
</template>

<script>

/**
 * Component to show the merchant primary column as an action link
 *
 * @module views/modules/merchant/MerchantListCellRendererLink
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-event {void} clickAction - to login as sub-user
 */
export default {
  name: 'MerchantListCellRendererLink',
  methods: {
    // todo: implementation to login as sub-user
    clickAction() {
      // eslint-disable-next-line no-alert
      alert('Here user login as sub-merchant clicked');
    },
  },
};
</script>
