<template>
  <cell-renderer-chip
    :options="chipOptions"
    :selected-option="params.value"/>
</template>

<script>
import CellRendererChip from '../_components/CellRendererChip.vue';

/**
 * Component to show the merchant status
 *
 * @module views/modules/merchant/MerchantListCellRendererStatus
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Array.<Object>} chipOptions - options(text, icon, color, value) to show the chip
 */
export default {
  name: 'MerchantListCellRendererStatus',
  components: {
    CellRendererChip,
  },
  data() {
    return {
      chipOptions: [
        {
          text: this.$t('$General.Invited'),
          value: 'invited',
          color: 'warning',
          icon: 'SendIcon',
        },
        {
          text: this.$t('$General.Active'),
          value: 'active',
          color: 'success',
          icon: 'UserCheckIcon',
        },
        {
          text: this.$t('$General.Inactive'),
          value: 'inactive',
          color: 'danger',
          icon: 'UserXIcon',
        },
      ],
    };
  },
};
</script>
