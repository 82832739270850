import { render, staticRenderFns } from "./MerchantListCellRendererStatus.vue?vue&type=template&id=f6d2f0f2&"
import script from "./MerchantListCellRendererStatus.vue?vue&type=script&lang=js&"
export * from "./MerchantListCellRendererStatus.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports